import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { BiCopyright } from 'react-icons/bi';

import {
  Wrapper,
  Company,
  Copyright,
  Privacy,
  SocialMediaColumn,
  SocialMedia,
} from './Footer.styled';

const Footer: React.FunctionComponent = () => {
  const today = new Date();
  return (
    <Wrapper>
      <SocialMediaColumn className="footer-row">
        <a
          className="social-media-link"
          href="https://www.facebook.com/SaberAstronautics/"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Access Saber Astronautic's Facebook page"
        >
          <SocialMedia>
            <FaFacebookF />
          </SocialMedia>
        </a>
        <a
          className="social-media-link"
          href="https://www.linkedin.com/company/saberastro"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Access Saber Astronautic's Linkedin page"
        >
          <SocialMedia>
            <FaLinkedinIn />
          </SocialMedia>
        </a>
        <a
          className="social-media-link"
          href="https://twitter.com/SaberAstro"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Access Saber Astronautic's Twitter page"
        >
          <SocialMedia>
            <FaTwitter />
          </SocialMedia>
        </a>
      </SocialMediaColumn>
      <Company className="footer-row">
        <Link to="/">
          <StaticImage
            src="../../images/logo.png"
            alt="Saber Astronautics Logo"
            height={64}
          />
        </Link>
        <Copyright>
          {today.getFullYear()}
          <BiCopyright style={{ verticalAlign: 'top', margin: '0px 5px' }} />
          SABER ASTRONAUTICS
          <br />
          ALL RIGHTS RESERVED.
        </Copyright>
      </Company>
      <Privacy className="footer-row">
        <Link to="/privacypolicy/">Privacy Policy</Link>
        <Link to="/termsofuse/">Terms of Use</Link>
      </Privacy>
    </Wrapper>
  );
};

export default Footer;
